#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-rd-flag-review-container {
            .pr-flag-review-thankyou {
              text-transform: capitalize;
            }
          }
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-content-block {
        &.pr-review-snapshot-header {
          display: block !important;
        }
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    #pr-war-form {
      .form-group {
        &.pr-media_image-form-group,
        &.pr-media_videourl-form-group {
          display: none;
        }
        &.pr-rating-form-group {
          margin-top: 20px;
        }
        &.pr-¿dondecomproesteproduto-form-group {
          #pr-¿dondecomproesteproduto {
            display: none;
          }
        }
        ::-webkit-input-placeholder {
          color: $color--black;
        }
        ::-moz-placeholder {
          color: $color--black;
        }
        :-ms-input-placeholder {
          color: $color--black;
        }
      }
    }
    .thank-you-page {
      .header {
        margin-top: 30px;
      }
    }
  }
}

#power_review_container {
  .product-full__container {
    .product-full__details {
      .pr-war-heading {
        display: block;
      }
    }
  }
}
