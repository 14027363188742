.checkout {
  .bottom-viewcart-buttons {
    .continue-buttons {
      position: relative;
      left: 17%;
    }
  }
  #viewcart-panel {
    .cart-item__price {
      display: block;
      span.cart-item__product-price--sale {
        margin-left: 0;
      }
      .cart-item {
        &__product-price--non-sale {
          text-decoration: line-through;
        }
        &__product-price--sale {
          color: $color--black;
        }
      }
    }
    .cart-item__desc-info {
      .flash-0 {
        display: none;
      }
    }
  }
}

.payment-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.two-column-form {
  width: 100%;
}
