.site-container {
  .collection-landing-brick__caption {
    margin-top: 0px;
  }
  #confirmation-page {
    .confirmation-panel {
      .checkout-header {
        .checkout-header__title {
          padding-left: 0%;
        }
      }
    }
  }
  .viewcart {
    .continue-buttons {
      text-align: center !important;
    }
  }
  .checkout {
    #order-summary-panel {
      #checkout_shipmethod {
        label {
          display: inline-block;
        }
      }
    }
  }
}

.artist--products {
  .product__size {
    display: none;
  }
}

.product__footer {
  .product_content_fav_mobile {
    display: inline-block;
    width: 100%;
  }
}

.waitlist-iframe-wrapper {
  width: 95%;
  height: 190px;
  margin: 17px 7px 5px 7px;
}

.tiny-waitlist-overlay {
  .waitlist_header {
    font-size: 20px;
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
  }
}

.field {
  &.clearfix {
    display: flex;
  }
  .email_input {
    float: left;
    width: 79%;
  }
  .align-r {
    float: left;
    margin-left: 10px;
    .input-btn {
      height: auto;
      margin-top: 0;
    }
  }
}

.search-results {
  .tout-block-landscape__caption {
    left: 0;
  }
  .search-results__summary {
    text-align: center;
  }
}

.page--spp-ultra-wide {
  .product--full {
    .shade-picker-dropdown {
      position: absolute;
      width: 320px;
      left: auto;
      right: 25%;
      padding: 0 7px;
    }
  }
}

.product__edd--pc {
  display: none;
}

.mpp-custom__header {
  border-top: none;
}

.mpp-custom__header--top {
  padding-top: 0;
}

.form-item {
  .estimated_delivery_date {
    display: none;
  }
}

.site-footer {
  .site-footer--sticky {
    .mac-select-loyalty-cols {
      .mac-select-loyalty-summary {
        &__header,
        &__content {
          text-align: center;
        }
      }
    }
    .site-email-signup {
      .site-email-signup__link {
        display: none;
      }
    }
    .site-email-signup__contents-header {
      .site-email-signup__contents-close {
        display: none;
      }
    }
    .site-email-signup__contents-title {
      a {
        color: $color--white;
        font-size: 28px;
        margin-top: 0.3em;
        margin-bottom: 0.1em;
        font-family: $ano-bold_regular-font;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
    .site-email-signup__contents-form {
      .field-container {
        .return-user-email-newsletter {
          width: 350px;
          height: 40px;
          float: left;
          display: inline-block;
          margin-left: 20px;
        }
        .site-email-signup__contents-submit {
          background: $color--white;
          color: $color--black;
          border-bottom: none;
          height: 45px;
          line-height: inherit;
        }
      }
    }
  }
}

.search-results__products {
  .grid--mpp__item {
    .prod_inv_status-2 {
      .product__add-to-bag {
        display: none;
      }
    }
  }
}
