.enhanced-cart-page {
  font-family: $helvetica-font;
  font-size: 15px;
  .checkout.viewcart {
    #samples-panel {
      .product__select.sample-select-container {
        .sample-select-button.button {
          width: 100%;
        }
      }
      .viewcart-samples__header--title {
        font-family: $ano-bold_regular-font;
      }
    }
    #viewcart-buttons-panel {
      .payments-icon {
        .master-card {
          background: url('/media/images/checkout/card_mastercard.png') no-repeat;
        }
        .visa-card {
          background: url('/media/images/checkout/card_visa.png') no-repeat;
        }
        .amex-card {
          background: url('/media/images/checkout/card_amex.png') no-repeat;
        }
        .diners-card {
          background: url('/media/images/checkout/card_diners.png') no-repeat;
        }
        .magna-card {
          background: url('/media/images/checkout/card_magna.png') no-repeat;
        }
        .cmr-card {
          background: url('/media/images/checkout/card_cmr.png') no-repeat;
        }
        .webpay {
          background: url('/media/images/checkout/webpay.jpg') no-repeat;
        }
        .paypal {
          display: none;
        }
        .master-card,
        .visa-card,
        .amex-card,
        .diners-card,
        .magna-card,
        .cmr-card,
        .webpay {
          @include swap_direction(margin, 5px 16px 0 0);
          background-position: center;
          background-size: 100%;
          height: 16px;
          width: 23px;
        }
      }
    }
    .checkout__content {
      .checkout-header__signin {
        width: 100%;
        margin: 23px 0 0;
      }
      .viewcart-panel__content {
        .cart-items.product-list {
          .cart-item__desc-info {
            .discount,
            .cart-item__total.price2 {
              top: 0;
              position: absolute;
              right: 0;
            }
            .cart-item__price {
              @include breakpoint($bp--medium-up) {
                flex-direction: column;
              }
              .cart-item__product-price--non-sale,
              .cart-item__product-price--sale {
                padding-bottom: 5px;
              }
              .cart-item__product-price--sale {
                margin: 0;
              }
            }
          }
        }
      }
    }
    .checkout__sidebar {
      .offer-code-panel {
        &__content {
          .offer-code__form {
            #offer_code {
              .offer-code__input {
                .field {
                  height: 48px;
                  background-color: $color--white;
                  font-family: $helvetica-font;
                }
              }
            }
          }
        }
        .offer-code__byline {
          padding: 0;
          color: $color-success;
          margin: 0 0 15px;
          @include breakpoint($bp--medium-up) {
            margin: 0 15px 15px;
          }
          a {
            color: $color--black;
          }
        }
      }
      .order-summary-panel {
        .label,
        .value {
          font-family: $ano-bold_regular-font;
          font-size: 15px;
          margin-bottom: 12px;
          @include breakpoint($bp--medium-up) {
            margin-bottom: 9px;
          }
        }
        .order-summary__shipping-content {
          .shipmethod-selectbox {
            font-family: $helvetica-font;
          }
        }
      }
      .links-panel {
        margin-bottom: 90px;
        &__title {
          border-bottom: 0;
        }
        &__list {
          a {
            font-family: $ano-black_regular-font;
            font-size: 15px;
          }
        }
      }
      .need-help-panel {
        .links_list {
          a {
            font-family: $ano-black_regular-font;
            font-size: 15px;
          }
        }
      }
    }
    .cart-item__price-content {
      position: relative;
      .cart-item__total {
        top: 0;
      }
      .cart-item__product-price {
        flex-direction: column;
        display: flex;
      }
    }
    .continue-checkout__lock--icon {
      margin-top: 6px;
      @include breakpoint($bp--medium-up) {
        margin-top: 0;
      }
    }
  }
  .viewcart-samples-panel#samples-panel-content {
    @include breakpoint($bp--medium-up) {
      margin-top: 22px;
    }
    .viewcart-samples__message {
      text-align: start;
    }
    .viewcart-samples__title.checkout-header__title {
      width: 0;
      margin: 0 0 10px;
    }
    .viewcart-samples {
      &__success-title,
      &__title {
        width: 100%;
      }
    }
  }
}

.product-brief-v2,
.product-full-v1 {
  .product-price--sale {
    color: $color--red;
  }
}
