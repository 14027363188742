.site-container {
  .responsive-container .picker-checkbox .picker-label {
    max-width: 85%;
  }
  .profile-info__fieldset {
    .birthday_col {
      margin-bottom: 15px;
    }
    .birthday_col {
      label {
        display: block;
        margin-bottom: 10px;
      }
      .select2-container {
        width: 27% !important;
        float: left;
        margin-right: 25px;
      }
    }
    .gender_col label {
      display: block;
    }
    .picker-radio {
      float: left;
      width: auto;
    }
    p {
      &.error-rut {
        color: $color--chestnut--red;
      }
    }
  }
  @media (min-width: 641px) {
    .order-details-page .cart-item .cart-item__thumb {
      width: 10.6%;
    }
  }
}

#colorbox {
  .address_form_container {
    .form-submit {
      margin-top: 20px;
    }
  }
}

label:not(.no-js-element) {
  display: inline;
}

.sign-in-page {
  a:not(.mantle-cta--custom-textcolor):visited {
    color: $color--white;
  }
}

.order-details-page {
  .cart-item {
    .cart-item__price.mobile_hidden {
      display: block;
    }
  }
}
